import React, { useEffect } from 'react'
import Map from 'ol/Map'
import View from 'ol/View'
import TileLayer from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'
import GPX from 'ol/format/GPX'
import GeoJSON from 'ol/format/GeoJSON'
import IGC from 'ol/format/IGC'
import KML from 'ol/format/KML'
import TopoJSON from 'ol/format/TopoJSON'
import DragAndDrop from 'ol/interaction/DragAndDrop'
import { defaults as defaultInteractions } from 'ol/interaction/defaults'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import KMZ from './KMZClass'
import 'ol/ol.css'
import './index.css'

// courtesy Openlayers https://github.com/openlayers/openlayers/blob/main/examples/drag-and-drop-custom-kmz.js
const dragAndDropInteraction = new DragAndDrop({
  formatConstructors: [KMZ, GPX, GeoJSON, IGC, KML, TopoJSON],
})

const KmzViewer = () => {
  const mapSources = [
    'World_Imagery',
    'World_Street_Map',
    'World_Topo_Map',

  ]

  const layers = mapSources.map((mapSource, index) => (
    new TileLayer({
      source: new XYZ({
        attributions: [
          'Powered by Esri',
        ],
        attributionsCollapsible: false,
        url: `https://services.arcgisonline.com/ArcGIS/rest/services/${mapSource}/MapServer/tile/{z}/{y}/{x}`,
        maxZoom: 23,
      }),
      ...(index > 0 ? { visible: false } : {}),
    })
  ))

  useEffect(() => {
    const map = new Map({
      interactions: defaultInteractions().extend([dragAndDropInteraction]),
      target: 'map',
      layers,
      view: new View({
        // EPSG:3857 WGS 84 / Pseudo-Mercator
        center: [3515840.58422089, 5645166.908574271],
        zoom: 8,
      }),
    })

    dragAndDropInteraction.on('addfeatures', (event) => {
      const vectorSource = new VectorSource({
        features: event.features,
      })
      map.addLayer(
        new VectorLayer({
          source: vectorSource,
        }),
      )
      map.getView().fit(vectorSource.getExtent())
    })

    return () => {
      map.setTarget(null)
    }
  }, [layers])

  const onMapSourceSelect = (e) => {
    const mapSource = e.currentTarget.value
    layers.forEach((layer, i) => layer.setVisible(mapSources[i] === mapSource))
  }

  return (
    <div className="kmz-viewer">
      <div id="map" className="kmz-viewer-map" />
      <div className="kmz-viewer-select-source-map">
        <select onChange={onMapSourceSelect}>
          {mapSources.map((source) => (
            <option key={source} value={source}>{source}</option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default KmzViewer
