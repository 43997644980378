import React, { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'
import { LanguageContext } from '../../contexts/language'
import ListItem from './ListItem'
import fetch from '../../utils/fetch'
import { UserContext } from '../../contexts/user'
import Input from '../Common/Input'
import ImportPopup from './ImportPopup'
import Button from '../Common/Button'
import { GREEN, RED, YELLOW } from '../../constants/buttons'
import { ADMINISTER, READ, WRITE } from '../../constants/permissions'
import './index.css'

const Info = () => {
  const { t } = useContext(LanguageContext)
  const [info, setInfo] = useState([])
  const [showForm, setShowForm] = useState(true)
  const [importCount, setImportCount] = useState(0)
  const [form, setForm] = useState({})
  const user = useContext(UserContext)
  const isReadingAllowed = user.permissions.includes(READ)
  const isAdmin = user.permissions.includes(ADMINISTER)
  const isEditingAllowed = isAdmin || user.permissions.includes(WRITE)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_HOST}/info`).then((response) => {
      setInfo(response)
    }).catch(() => {})
  }, [importCount])

  const onChangeInput = (e) => {
    setForm({
      ...form,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  const onChangeUnsure = (e) => {
    setForm({ ...form, unsure: e.target.checked })
  }

  const onClickCancel = () => {
    setForm({})
  }

  const onDelete = (id) => {
    if (!window.confirm(t('info.on-delete-confirm'))) {
      return
    }
    fetch(
      `${process.env.REACT_APP_API_HOST}/info`, {
        method: 'DELETE',
        body: JSON.stringify({ id }),
      })
      .then(() => {
        setInfo([...info.filter((item) => item.id !== id)])
      }).catch(() => {})
  }

  const onDeleteAll = () => {
    if (!window.confirm(t('info.on-delete-all-confirm'))) {
      return
    }
    fetch(
      `${process.env.REACT_APP_API_HOST}/info/all`, {
        method: 'DELETE',
      }).then(() => { setInfo([]) }).catch(() => {})
  }

  const onUpdate = (item) => {
    setInfo([...info.map((currItem) => (currItem.id === item.id ? item : currItem))])
  }

  const onClickAdd = () => {
    const { callsign, callsignTlg: callsign_tlg, correspondent, comment, unsure } = form
    fetch(
      `${process.env.REACT_APP_API_HOST}/info`, {
        method: 'POST',
        body: JSON.stringify({
          callsign,
          callsign_tlg,
          correspondent,
          comment,
          unsure,
        }),
      }).then((response) => {
      setInfo([response, ...info])
      setForm({})
    }).catch(() => {})
  }

  const onImport = () => setImportCount(importCount + 1)

  const filterInfo = (item) => (
    (item.callsign || '').toLowerCase().includes(form.callsign?.toLowerCase() || '')
    && (item.callsign_tlg || '').toLowerCase().includes(form.callsignTlg?.toLowerCase() || '')
    && (item.correspondent || '').toLowerCase().includes(form.correspondent?.toLowerCase() || '')
    && (item.comment || '').toLowerCase().includes(form.comment?.toLowerCase() || '')
    && (form.unsure ? item.unsure === 1 : true)
  )

  const sortInfo = (a, b) => (b.added_at - a.added_at)

  if (!isReadingAllowed) {
    return <Navigate to="/" />
  }

  const inputs = [
    { title: t('info.form.correspondent'), name: 'correspondent' },
    { title: t('info.form.telephone'), name: 'callsign' },
    { title: t('info.form.telegraph'), name: 'callsignTlg' },
    { title: t('info.form.comment'), name: 'comment' },
  ]
  const headers = inputs.map(({ title }) => title)

  return (
    <div className="info">
      {!showForm && (
        <div className="info__add-open" title={t('info.open-form-button.title')}>
          <Button onClick={() => setShowForm(true)}>
            <FontAwesomeIcon icon={faPlus} color="#555555" />
          </Button>
        </div>
      )}
      {showForm && (
        <div className="info__add">
          <div className="info__add-close">
            <FontAwesomeIcon icon={faTimes} color="#555555" onClick={() => setShowForm(false)} />
          </div>
          {inputs.map(({ title, name }) => (
            <Input
              key={name}
              placeholder={title}
              name={name}
              className="info__add-input"
              type="text"
              onChange={onChangeInput}
              value={form[name]}
            />
          ))}
          <label className="info__add-checkbox-label" htmlFor="unsure">
            <input
              className="info__add-checkbox"
              type="checkbox"
              id="unsure"
              name="unsure"
              onChange={onChangeUnsure}
              checked={!!form.unsure}
            />
            {t('info.form.unsure.title')}
          </label>
          <div className="info__buttons">
            <div className="info__button">
              <Button
                onClick={onClickCancel}
                isDisabled={!form.callsign && form.callsignTlg && !form.correspondent && !form.comment && !form.unsure}
              >
                {t('info.form.clear-button.title')}
              </Button>
            </div>
            {isEditingAllowed && (
              <>
                <div className="info__button">
                  <Button
                    onClick={onClickAdd}
                    isDisabled={!form.callsign && !form.callsignTlg && !form.correspondent && !form.comment}
                    type={GREEN}
                  >
                    {t('info.form.add-button.title')}
                  </Button>
                </div>
                {isAdmin && (
                  <>
                    <div className="info__button">
                      <ImportPopup onImport={onImport}>
                        <Button type={YELLOW}>
                          {t('info.form.import-button.title')}
                        </Button>
                      </ImportPopup>
                    </div>
                    <div className="info__button">
                      <Button type={RED} onClick={onDeleteAll}>
                        {t('info.form.delete-all-button.title')}
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <div className="info__list-headers">
        {headers.map((title) => <div className="info__list-headers-item" key={title}>{title}</div>)}
      </div>
      <div className="info__list">
        {info.filter(filterInfo).sort(sortInfo).map((item) => (
          <ListItem {...item} onDelete={onDelete} onUpdate={onUpdate} key={item.id} />
        ))}
      </div>
    </div>
  )
}

export default Info
