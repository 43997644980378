import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import Input from '../Common/Input'
import 'react-toastify/dist/ReactToastify.css'
import { UserLoginContext } from '../../contexts/user'
import { LanguageContext } from '../../contexts/language'
import fetch from '../../utils/fetch'
import 'react-notifications-component/dist/theme.css'
import './index.css'

const LOGIN = 'login'
const REGISTER = 'register'

const Auth = () => {
  const { t } = useContext(LanguageContext)
  const [action, setAction] = useState(LOGIN)
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const onLogIn = useContext(UserLoginContext)
  const passwordInputType = showPassword ? 'text' : 'password'

  const onChangeName = (e) => {
    setName(e.currentTarget.value)
    setError('')
  }

  const onChangePassword = (e) => {
    setPassword(e.currentTarget.value)
    setError('')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    fetch(
      `${process.env.REACT_APP_API_HOST}/user/${action}`, {
        method: 'POST',
        body: JSON.stringify({ name, password }),
      }).then((response) => {
      setName('')
      setPassword('')
      onLogIn(response)
    }).catch((response) => {
      setError(response.error)
      toast.error(response.error)
    })
  }

  const title = action === LOGIN ? t('auth.sign-in-title') : t('auth.sign-up-title')
  const buttonTitle = action === LOGIN ? t('auth.sign-in-title') : t('auth.sign-up-title')
  const linkTitle = action === LOGIN ? t('auth.sign-up-title') : t('auth.sign-in-title')

  return (
    <div className="auth">
      <h1>{title}</h1>
      <form className={`auth__form${error ? ' auth__form_error' : ''}`} onSubmit={onSubmit}>
        <div className="auth__input">
          <Input
            placeholder={t('auth.login-placeholder')}
            className="auth__input-input"
            type="text"
            onChange={onChangeName}
            value={name}
          />
        </div>
        <div className="auth__input">
          <Input
            placeholder={t('auth.password-placeholder')}
            className="auth__input-input auth__input-password"
            type={passwordInputType}
            onChange={onChangePassword}
            value={password}
          />
          <div
            className="auth__show-password"
            onClick={() => setShowPassword(!showPassword)}
            title={showPassword ? t('auth.hide-password') : t('auth.show-password')}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </div>
        </div>

        <button
          type="submit"
          className="auth__button"
          disabled={!name.trim() || !password.trim()}
        >
          {buttonTitle}
        </button>
      </form>
      <div
        className="auth__link"
        onClick={() => setAction(action === LOGIN ? REGISTER : LOGIN)}
      >
        {linkTitle}
      </div>
    </div>
  )
}

export default Auth
