import { unzipSync } from 'fflate'
import KML from 'ol/format/KML'

let zip

const getKMLData = (buffer) => {
  zip = unzipSync(new Uint8Array(buffer))
  const kml = Object.keys(zip).find((key) => /\.kml$/i.test(key))
  if (!(kml in zip)) {
    return null
  }
  return new TextDecoder().decode(zip[kml])
}

const getKMLImage = (href) => {
  const index = window.location.href.lastIndexOf('/')
  if (index === -1) {
    return href
  }
  const image = href.slice(index + 1)
  if (!(image in zip)) {
    return href
  }
  return URL.createObjectURL(new Blob([zip[image]]))
}

// Define a KMZ format class by subclassing ol/format/KML

class KMZClass extends KML {
  constructor(opt_options) {
    const options = opt_options || {}
    options.iconUrlFunction = getKMLImage
    super(options)
  }

  // eslint-disable-next-line class-methods-use-this
  getType() {
    return 'arraybuffer'
  }

  readFeature(source, options) {
    const kmlData = getKMLData(source)
    return super.readFeature(kmlData, options)
  }

  readFeatures(source, options) {
    const kmlData = getKMLData(source)
    return super.readFeatures(kmlData, options)
  }
}

export default KMZClass
