import React, { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import { generatePath } from 'react-router'
import { ToastContainer, Slide } from 'react-toastify'
import { UserContext } from '../../contexts/user'
import { LanguageContext } from '../../contexts/language'
import Menu from '../Menu'
import Info from '../Info'
import Auth from '../Auth'
import Home from '../Home'
import Users from '../Users'
import MapZone from '../MapZone'
import CoordsConverter from '../CoordsConverter'
import BeaconsEditor from '../BeaconsEditor'
import DistanceFinder from '../DistanceFinder'
import Points from '../Points'
import KmzViewer from '../KmzViewer'
import Page404 from '../Page404'
import { ADMINISTER, READ, WRITE } from '../../constants/permissions'
import './index.css'

const App = () => {
  const user = useContext(UserContext)
  const canRead = user?.permissions.includes(READ)
  const canWrite = user?.permissions.includes(WRITE)
  const isAdmin = user?.permissions.includes(ADMINISTER)
  const { locale } = useContext(LanguageContext)
  return (
    <>
      <Menu />
      {user && (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path={generatePath('/:locale/zone', { locale })} element={<MapZone />} />
          <Route path={generatePath('/:locale/points', { locale })} element={<Points />} />
          <Route path={generatePath('/:locale/distance', { locale })} element={<DistanceFinder />} />
          <Route path={generatePath('/:locale/converter', { locale })} element={<CoordsConverter />} />
          <Route path={generatePath('/:locale/kmz-viewer', { locale })} element={<KmzViewer />} />
          {canRead && <Route path={generatePath('/:locale/info', { locale })} element={<Info />} />}
          {canWrite && <Route path={generatePath('/:locale/beacons', { locale })} element={<BeaconsEditor />} />}
          {isAdmin && <Route path={generatePath('/:locale/admin/users', { locale })} element={<Users />} />}
          <Route path="*" element={<Page404 />} />
        </Routes>
      )}
      {!user && <Auth />}
      <ToastContainer position="top-center" hideProgressBar autoClose={2000} transition={Slide} />
    </>
  )
}

export default App
